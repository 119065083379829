import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  image?: ImageProps
  layouts?: LayoutProps[]
  size?: string
  title?: string
  weight?: number
}

export const MeetingRoom = memo(function MeetingRoom({
  cta,
  description,
  image,
  layouts,
  size,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }

  return (
    <Container>
      <Fade bottom distance="3.75rem">
        <Wrapper className="room-item" dial={5} row stretch wrap>
          <LeftSide dial={4}>
            {title ? <Title>{title}</Title> : null}
            {size ? <Size>{size}</Size> : null}
            {description ? (
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            ) : null}
            {layouts ? (
              <Layouts row wrap space="between">
                {uniq(layouts).map((item, index) => (
                  <Layout key={index}>
                    {item.icon ? (
                      <Icon
                        src={item.icon}
                        width="40"
                        height="24"
                        alt={item.label}
                      />
                    ) : null}

                    <Label>{item.label}</Label>
                  </Layout>
                ))}
              </Layouts>
            ) : null}
            {cta
              ? cta.map((item, index) => (
                  <CTA key={index} {...item} variant="simple" />
                ))
              : null}
          </LeftSide>
          <RightSide>
            <MainImage>
              {image ? (
                <LazyLoad>
                  <Image {...image} />
                </LazyLoad>
              ) : null}
            </MainImage>
          </RightSide>
        </Wrapper>
      </Fade>
    </Container>
  )
})

export interface LayoutProps {
  icon?: string
  label?: string
}

const Container = styled.div`
  max-width: 77.125rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  &:nth-of-type(odd) {
    .room-item {
      > div {
        &:first-of-type {
          order: 2;
          padding-right: 6.25vw;
          padding-left: 9.306vw;
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin: 7.5rem auto;
    &:nth-of-type(odd) {
      .room-item {
        > div {
          &:first-of-type {
            padding-right: 1.5rem;
            padding-left: 3.75rem;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    &:nth-of-type(odd) {
      .room-item {
        > div {
          &:first-of-type {
            padding: 0;
          }
        }
      }
    }
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled(FlexBox)`
  width: 50%;
  padding-right: 9.306vw;
  padding-left: 6.25vw;
  .lazyload-wrapper {
    width: 100%;
  }

  @media (max-width: 1023px) {
    padding-right: 3.75rem;
    padding-left: 1.5rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    padding: 0;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 2.9375rem;
`

const Size = styled.span`
  color: ${({ theme }) => theme.colors.variants.primaryDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin: 0.375rem 0 1.875rem;
  text-transform: uppercase;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.75rem;
`

const Layouts = styled(FlexBox)`
  width: 100%;
  margin-top: 3rem;

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const CTA = styled(Button)`
  margin-top: 70px;

  @media (max-width: 1023px) {
    margin-top: 3.75rem;
  }
`

const Layout = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 1.5rem;
  text-align: center;

  @media (max-width: 767px) {
    max-width: none;
  }
`

const Icon = styled.img`
  width: auto;
  height: 1.5rem;
  margin-bottom: 1.125rem;
`

const Label = styled.label`
  display: block;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  line-height: 1.125rem;
`

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const MainImage = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-bottom: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    padding-bottom: 66%;
    margin-bottom: 2.25rem;
  }
`
